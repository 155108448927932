export enum OrderFulfillmentStatus {
    notAccepted = 'NOT_ACCEPTED',
    accepted = 'ACCEPTED',
    dispatched = 'DISPATCHED',
    arrivedAtPickup = 'ARRIVED_AT_PICKUP',
    surveyAtPickup = 'SURVEY_AT_PICKUP',
    awaitingSignature = 'AWAITING_SIGNATURE',
    awaitingPayment = 'AWAITING_PAYMENT',
    pickupStarted = 'PICKUP_STARTED',
    inTransit = 'IN_TRANSIT',
    arrivedAtDelivery = 'ARRIVED_AT_DELIVERY',
    surveyAtDelivery = 'SURVEY_AT_DELIVERY',
    //awaitingSignature
    //awaitingPayment
    deliveryStarted = 'DELIVERY_STARTED',
    //awaitingSignature
    delivered = 'DELIVERED',
    paymentFailed = 'PAYMENT_FAILED',
    cancelled = 'CANCELLED',
    unpaid = 'UNPAID',
    forfeited = 'FORFEITED',
    paymentDispute = 'PAYMENT_DISPUTE',
    onHold = 'ON_HOLD'
}