import {Progress} from "cn/components/ui/progress";
import {Toaster} from "cn/components/ui/toaster";
import Dashboard from "cn/custom/dashboard/dashboard";
import Header from "cn/custom/Header";
import SheetWrapper from "cn/custom/sheet/sheetWrapper";
import ScreenView from "pages/main/details/orderDetails/sections/media/ScreenView";
import {useEffect, useState} from "react";
import {defaultDimensions} from "utils/config/defaultDimensions";
import useMenubar from "utils/zustand/tables/useMenubar";
import useProgressBar from "utils/zustand/primary/useProgressBar";
import useViewport from "utils/zustand/useViewport";
import { User } from 'oidc-client-ts'
import {zitadel} from "../../../utils/zitadel-manager";

interface Props {
    children: React.ReactNode,
}

export default function Layout({children}: Props) {
    const {width, setDimensions} = useViewport();
    const {checkCurrentURL} = useMenubar();
    const {progressValue} = useProgressBar();
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    const [userInfo, setUserInfo] = useState<User | null>(null)

    const checkUserOrRedirectResponse = async () => {
        try {
            const user = await zitadel.userManager.getUser()

            if (user) {
                setAuthenticated(true)
                setUserInfo(user)
                return
            }

            if (!user) {
                const res = await zitadel.userManager.signinRedirectCallback()
                if (res) {
                    setAuthenticated(true)
                    setUserInfo(res)
                    return
                }
            }

        } catch {
            zitadel.authorize()
        }
    }

    const initUserIfAuthenticated = async () => {
        try {
            const res = await zitadel.userManager.getUser()
            if (res) setUserInfo(res)
            if (!res) {
                setAuthenticated(false)
                zitadel.authorize()
            }
        } catch (e) {
            zitadel.authorize()
            console.log(e)
        }
    }

    useEffect(() => {
        if (!authenticated) checkUserOrRedirectResponse()
        if (authenticated && !userInfo) initUserIfAuthenticated()
    }, [authenticated, userInfo])

    useEffect(() => {
        window.addEventListener("resize", setDimensions);
        return () => window.removeEventListener("resize", setDimensions);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        checkCurrentURL(window.location.pathname)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    return <>

        {!authenticated && <div className={'h-dvh w-dvw grid items-center'}>Loading</div>}

        {(authenticated && userInfo) && <div className={'h-[100dvh] w-full flex flex-col'}>
                <ScreenView/>

                <div className={'fixed z-10 w-full bg-white'}>
                    <Progress className={"z-50"} value={progressValue}/>
                    <Header/>
                </div>

                <section
                    className={"grow flex"}>
                    {width >= defaultDimensions.lg && <Dashboard/>}
                    <div
                        className={`grow px-6 py-6 mt-[4rem] ${width >= defaultDimensions.lg ? 'ml-60' : ''}`}>
                        <SheetWrapper/>
                        {children}
                    </div>
                </section>
            </div>
        }
        <Toaster/>
    </>
}