import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {Customer} from "utils/types/primary/Customer";
import {zitadel} from "../zitadel-manager";

export default async function updateCustomer(customer: Customer) {
    const user = await zitadel.userManager.getUser()

    try {
        const res = await fetch(ApiHost + `api/v1/customer/${customer.id}`, {
            method: 'PUT',
            body: JSON.stringify({...customer}),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${user?.access_token}`,
            }
        })

        if (res.ok) {
            const data: Customer = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}