import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {zitadel} from "../zitadel-manager";

export default async function deleteApplication(applicationId: string) {
    const user = await zitadel.userManager.getUser()

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/application/${applicationId}`), {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${user?.access_token}`
            }
        })

        if (res.ok) {
            return {res: 'OK'}
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}