import { ApiHost } from "utils/config/config"
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {zitadel} from "../../../../../../utils/zitadel-manager";


export default async function deleteCurrentFile(orderId: string, fileId: string) {
    const user = await zitadel.userManager.getUser()

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/file/${orderId}/${fileId}`), {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${user?.access_token}`
            }
        })

        if (res.ok) {
            return 200
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}