import DashboardSheet from "cn/custom/dashboard/dashboardSheet";
import {ChevronDown} from "lucide-react";
import {useEffect, useState} from "react";
import {defaultDimensions} from "utils/config/defaultDimensions";
import {User} from 'oidc-client-ts'
import useViewport from "utils/zustand/useViewport";
import {ReactComponent as MarcoLogo} from "../../media/marcoLogo.svg";
import {ReactComponent as TriplePod} from "../../media/triplePod.svg";
import {zitadel} from "../../utils/zitadel-manager";

export default function Header() {

    const {width} = useViewport();
    const [isOpen, setOpen] = useState<boolean>(false)
    const [userInfo, setUserInfo] = useState<User | null>(null)

    useEffect(() => {
        if (!userInfo) {
            zitadel.userManager.getUser().then(res => {
                if (res) setUserInfo(res)
            })
        }

    }, [userInfo]);

    return <div className={'px-4 h-16 border-b flex justify-between items-center'}>
        <div className={'flex gap-2 place-items-center'}>
            <DashboardSheet isOpen={isOpen} setOpen={setOpen}/>
            {width <= defaultDimensions.lg &&
                <div
                    onClick={() => setOpen(true)}
                    className={'py-1 px-1 rounded-full cursor-pointer itemHover'}><TriplePod/></div>}
            <MarcoLogo/>
        </div>

        <div onClick={() => zitadel.signout()}
             className={'flex place-items-center gap-[0.613rem] cursor-pointer itemHover py-2 px-2'}>
            <p className={'font-inter text-marcoDefaultTextColor text-sm font-semibold'}>{userInfo?.profile.name}</p>
            <ChevronDown className={'h-4'}/>
        </div>


    </div>
}