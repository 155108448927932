const routes = {
    statisticsBoard: '/',
    orders: '/orders',
    drafts: '/drafts',
    quotes: '/quotes',
    customers: '/customers',
    companies: '/companies',
    members: '/members',
    finances: '/finances',
    refunds: '/refunds',
    payouts: '/payouts',
    inventory: '/inventory',
    categories: '/categories',
    presets: '/presets',
    rates: '/rates',
    applications: '/applications',

    orderDetails: '/order_details',
    customerDetails: '/customer_details',
    companyDetails: '/company_details',
    memberDetails: '/member_details',

    createCompany: '/create_company',
    createMember: '/create_member',

    editCompany: '/edit_company'
}

export default routes