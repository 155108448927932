import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import {zitadel} from "../zitadel-manager";


const fetcher = async (url: string) => {
    const user = await zitadel.userManager.getUser()

    const res: Response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${user?.access_token}`,
            "content-type": "application/json"
        }
    })

    if (res.ok) {
        return res.json()
    } else {
        const definedError = await getDefinedError(res)
        throw definedError
    }
}
export default fetcher