import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {Order} from "utils/types/primary/Order";
import {zitadel} from "../zitadel-manager";

export default async function approveOrder(order: Order) {
    const user = await zitadel.userManager.getUser()

    try {
        const res: Response = await fetch(ApiHost + encodeURI(`api/v1/order/${order?.id}/approve`), {
            method: 'POST',
            body: JSON.stringify(order.id),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${user?.access_token}`
            }
        })

        if (res.ok) {
            return 200
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}