import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {Order} from "utils/types/primary/Order";
import {zitadel} from "../zitadel-manager";

export default async function updateOrder(order: Order) {
    const user = await zitadel.userManager.getUser()

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/order/${order?.id}`),
            {
                method: 'PUT',
                body: JSON.stringify(order),
                headers: {
                    Authorization: `Bearer ${user?.access_token}`,
                    "content-type": "application/json"
                }
            });

        if (res.ok) {
            const data:Order = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}