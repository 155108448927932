import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import { Address } from "utils/types/primary/Address";
import {zitadel} from "../zitadel-manager";

export type UpdatedAddress = {
    address: string,
    orderId: string,
    direction: string,
    propertyType: string | null,
    unitNumber: string,
    floorNumber: string,
    numberStories: string | null,
    squareFootage: string | null,
    access: string,
    unitSize: string | null,
    propertySize: string | null
}


export default async function submitUpdatedAddress(address: UpdatedAddress) {
    const user = await zitadel.userManager.getUser()

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/address/`), {
            method: 'POST',
            body: JSON.stringify(address),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${user?.access_token}`
            }
        })

        if (res.ok) {
            const data: Address = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}