import ApplicationDetails from "pages/main/details/applicationDetails/applicationDetails";
import CompanyDetails from "pages/main/details/companyDetails/companyDetails";
import EditCompany from "pages/main/details/companyDetails/components/editCompany";
import CreateCompany from "pages/main/details/companyDetails/createCompany";
import CustomerDetails from "pages/main/details/customerDetails/customerDetails";
import MemberDetails from "pages/main/details/memberDetails/memberDetails";
import Applications from "pages/main/tables/applications/applications";
import Categories from "pages/main/tables/categories/categories";
import Companies from "pages/main/tables/companies/companies";
import Customers from "pages/main/tables/customers/customers";
import Drafts from "pages/main/tables/ordersTables/drafts";
import Finances from "pages/main/tables/finances/finances";
import Inventory from "pages/main/tables/inventory/inventory";
import Layout from "pages/main/layout/layout";
import Members from "pages/main/tables/members/members";
import OrderDetails from "pages/main/details/orderDetails/orderDetails";
import Orders from "pages/main/tables/ordersTables/orders";
import Payouts from "pages/main/tables/payouts/payouts";
import Presets from "pages/main/tables/presets/presets";
import Quotes from "pages/main/tables/ordersTables/quotes";
import Rates from "pages/main/tables/rates/rates";
import Refunds from "pages/main/tables/refunds/refunds";
import StatisticsBoard from "pages/main/details/statisticsBoard/statisticsBoard";
import React from 'react';
import './globals.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import routes from "utils/config/routes";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<Layout><StatisticsBoard/></Layout>}/>
                <Route path={routes.orders} element={<Layout><Orders/></Layout>}/>
                <Route path={routes.drafts} element={<Layout><Drafts/></Layout>}/>
                <Route path={routes.quotes} element={<Layout><Quotes/></Layout>}/>
                <Route path={routes.customers} element={<Layout><Customers/></Layout>}/>
                <Route path={routes.companies} element={<Layout><Companies/></Layout>}/>
                <Route path={routes.members} element={<Layout><Members/></Layout>}/>
                <Route path={routes.finances} element={<Layout><Finances/></Layout>}/>
                <Route path={routes.refunds} element={<Layout><Refunds/></Layout>}/>
                <Route path={routes.payouts} element={<Layout><Payouts/></Layout>}/>
                <Route path={routes.inventory} element={<Layout><Inventory/></Layout>}/>
                <Route path={routes.categories} element={<Layout><Categories/></Layout>}/>
                <Route path={routes.presets} element={<Layout><Presets/></Layout>}/>
                <Route path={routes.rates} element={<Layout><Rates/></Layout>}/>
                <Route path={routes.applications} element={<Layout><Applications/></Layout>}/>

                <Route path={`${routes.orderDetails}/:id`} element={<Layout><OrderDetails/></Layout>}/>
                <Route path={`${routes.customerDetails}/:id`} element={<Layout><CustomerDetails/></Layout>}/>
                <Route path={`${routes.companyDetails}/:id`} element={<Layout><CompanyDetails/></Layout>}/>
                <Route path={`${routes.memberDetails}/:id`} element={<Layout><MemberDetails/></Layout>}/>
                <Route path={`${routes.applications}/:id`} element={<Layout><ApplicationDetails/></Layout>}/>

                <Route path={`${routes.editCompany}/:id`} element={<Layout><EditCompany/></Layout>}/>

                <Route path={routes.createCompany} element={<Layout><CreateCompany/></Layout>}/>
            </Routes>
        </BrowserRouter>
    );
}
